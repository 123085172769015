.job .banner {
  background: url('/assets/img/background/img-header.png') no-repeat center
    center;
  background-size: cover;
  height: 35rem;
}
.job .banner .parallax-container {
  height: 35rem;
  position: relative;
}
.job .banner .parallax-images {
  position: absolute;
  width: 100%;
}
.job .banner .parallax-images {
  background: url('/assets/img/background/svg/icons/img-illustrations.svg')
    repeat;
  background-size: cover;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .job .banner .parallax-images {
    background-size: 100%;
    height: 200%;
  }
}
.job .banner .parallax-images {
  height: 150%;
  width: 95%;
}
.job .banner h1 {
  font-size: 4rem;
  letter-spacing: 0.24rem;
  margin-bottom: 3.8rem;
  padding-top: 10.8rem;
  text-align: center;
}
.job .banner .btn {
  font-size: 1.8rem;
  padding: 1.8rem;
  width: 23.9rem;
}
.job .banner img {
  display: block;
  margin: 0.02rem auto;
}
@media screen and (min-width: 992px) {
  .job .banner {
    height: 60rem;
  }
  .job .banner h1 {
    font-size: 5rem;
    letter-spacing: 0.18rem;
    margin-bottom: 6rem;
    padding-top: 21.5rem;
  }
  .job .banner .btn {
    font-size: 2rem;
    padding: 1.8rem;
    width: 39.9rem;
    margin-bottom: 0.8rem;
  }
  .job .banner img {
    height: 5.8rem;
    margin-top: 3.7rem;
  }
}
.job .job-offers {
  background-image: radial-gradient(circle at 57% 53%, #f7f7f7, #eaeaea);
  background-size: cover;
}
.job .job-offers .page {
  margin-top: 4rem;
}
.job .job-offers h2 {
  margin-bottom: 2.8rem;
  margin-left: 3.6rem;
  font-size: 2.4rem;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  letter-spacing: 0.18rem;
  margin-top: 1.4rem;
  text-transform: uppercase;
}
.job .job-offers h3 {
  font-family: 'DINPro-Bold', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2rem;
  margin-left: 3.7rem;
  text-transform: uppercase;
}
.job .job-offers p {
  font-family: 'DINPro-Light', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.8rem;
  margin: 1.7rem 3.8rem 2rem;
}
.job .job-offers a {
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.job .job-offers ul {
  list-style-type: none;
  margin: 0.5rem 1.89rem 0.2rem 2.9rem;
  padding: 1px 0 1px 15px;
}
.job .job-offers ul li {
  padding-top: 1.5rem;
}
.job .job-offers ul li::before {
  background-color: #ffc200;
  content: '';
  display: inline-block;
  float: left;
  height: 0.5rem;
  margin-right: 0.6rem;
  margin-top: 1.2rem;
  vertical-align: middle;
  width: 0.5rem;
}
.job .job-offers .outside {
  margin-bottom: 6rem;
  margin-left: 3.3rem;
  margin-right: 1.4rem;
  border-radius: 0;
}
.job .job-offers .inside {
  background-color: transparent;
  border-color: #ffc200;
  border-radius: 0;
  border-width: 0.1rem;
  margin: 2rem 2rem -2rem -2rem;
}
.job .job-offers .card-link {
  margin-left: 1.8rem;
}
.job .job-offers .btn {
  background-image: linear-gradient(to right, #ffcb00, #f7971e, #ffcb00 30%);
  background-position: 60%;
  background-size: 200%;
  cursor: pointer;
  transition: 1s;
  width: 21.7rem;
}
.job .job-offers .btn:hover,
.job .job-offers .btn:focus,
.job .job-offers .btn:active,
.job .job-offers .btn:visited {
  outline: 0;
  box-shadow: none;
}
.job .job-offers .btn:hover {
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: changeBackgroundPosition;
}
@media screen and (min-width: 768px) {
  .job .job-offers .page {
    margin-top: 6rem;
  }
  .job .job-offers h2 {
    margin-bottom: 2.9rem;
    margin-left: 4rem;
    font-size: 3rem;
    margin-top: 2.1rem;
    text-align: center;
  }
  .job .job-offers h3 {
    margin-left: 6.4rem;
  }
  .job .job-offers p {
    margin: 1.5rem 4.8rem 2.3rem 6.5rem;
  }
  .job .job-offers ul {
    margin-left: 5rem;
  }
  .job .job-offers .outside {
    margin-bottom: 9rem;
    margin-left: 8.3rem;
    margin-right: 6.4rem;
  }
  .job .job-offers .inside {
    border-width: 0.2rem;
  }
  .job .job-offers .btn {
    padding-left: 4.2rem;
    width: 51.2rem;
  }
}
@media screen and (min-width: 992px) {
  .job .job-offers h2 {
    margin-bottom: 5rem;
    margin-left: 3.2rem;
    font-size: 3rem;
    letter-spacing: 0.2rem;
    margin-top: 3.1rem;
  }
  .job .job-offers h3 {
    margin-left: 6rem;
  }
  .job .job-offers p {
    margin: 2.3rem 0 2.3rem 6.5rem;
  }
  .job .job-offers ul {
    margin-left: 5rem;
  }
  .job .job-offers .outside {
    margin-bottom: 9rem;
    margin-left: 10.8rem;
    margin-right: 10.8rem;
  }
  .job .job-offers .btn {
    margin-left: 4.1rem;
    width: 100%;
  }
}

/* ------------- EFFECTS -------------- */
@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  22%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  25%,
  47% {
    transform: translate3d(0, -33.33%, 0);
  }
  50%,
  72% {
    transform: translate3d(0, -66.66%, 0);
  }
  75%,
  97% {
    transform: translate3d(0, -33.33%, 0);
  }
}

.content {
  height: 2rem;
  overflow: hidden;
}
.content-container {
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.content-container-list {
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: change;
  list-style: none;
  padding-left: 1rem;
  text-align: left;
}
.content-container-list-item {
  margin-bottom: 3rem;
}

@media screen and (min-width: 768px) {
  .content {
    height: 3rem;
  }
}

@media screen and (min-width: 992px) {
  .content {
    height: 5rem;
  }
  .content-container-list {
    padding-left: 2rem;
  }
}
